<style lang="scss">
.rechner-all {
  border: 0px solid red;
}
</style>

<template>
  <div class="calculator content container">
    <h1>Druckkosten- und Beilagenrechner</h1>
    <div class="rechner-all">
      <Tarifrechner :tarifData="tarifData"> </Tarifrechner>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import Tarifrechner from '@/components/tarifrechner/Tarifrechner.vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'Calculator',
  components: {
    Tarifrechner,
  },
  setup() {
    const store = useStore();
    const tarifData = ref(store.state.tarifrechner);

    return {
      tarifData,
    };
  },
});
</script>
