
import { defineComponent, ref } from 'vue';
import Tarifrechner from '@/components/tarifrechner/Tarifrechner.vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'Calculator',
  components: {
    Tarifrechner,
  },
  setup() {
    const store = useStore();
    const tarifData = ref(store.state.tarifrechner);

    return {
      tarifData,
    };
  },
});
