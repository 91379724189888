<style>
.datepicker {
  background-color: #fff !important;
}
</style>

<template>
  <input v-bind="props" class="form-control datepicker" type="text" ref="input" v-model="value" />
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watchEffect } from 'vue';
import 'flatpickr/dist/flatpickr.css';
import flatpickr from 'flatpickr';
import { German } from 'flatpickr/dist/l10n/de.js';
import { BaseOptions } from 'flatpickr/dist/types/options';

export default defineComponent({
  props: {
    options: Object,
    modelValue: String,
    placeholder: String,
    class: String,
    id: String,
    style: String,
  },
  setup(props, { emit }) {
    const input = ref<Node>();
    const altField = ref<Node>();
    const value = computed({
      get: () => props.modelValue,
      set: value => emit('update:modelValue', value),
    });

    onMounted(() => {
      if (input.value) {
        flatpickr(input.value, {
          locale: German,
          dateFormat: 'Z',
          altFormat: 'l, j. F Y',
          altInput: true,
          ...props.options,
        });
      }
    });

    return {
      input,
      value,
      props,
      altField,
    };
  },
});
</script>
